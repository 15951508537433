var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "8"
    }
  }, [_c('b-card', [_c('h1', {
    staticClass: "text-center mb-2"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v(" Rp " + _vm._s(_vm.formatRupiahAkun(_vm.akun.saldo)) + " ")])]), _c('table', {
    staticClass: "w-full table"
  }, [_c('tr', [_c('td', [_vm._v("Nama Akun")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.akun.nama))])]), _c('tr', [_c('td', [_vm._v("Nomor Akun")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.akun.nomor))])]), _c('tr', [_c('td', [_vm._v("Jenis Akun")]), _c('td', [_vm._v(":")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.akun.jenis == 1 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(" " + _vm._s(_vm.akun.jenis == 1 ? 'Debit' : 'Kredit') + " ")])], 1)]), _c('tr', [_c('td', [_vm._v("Kategori Akun")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.akun.kategori ? _vm.akun.kategori.kategori : '-'))])]), _c('tr', [_c('td', [_vm._v("Jenis Neraca")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.akun.jenis_neraca ? _vm.akun.jenis_neraca.neraca : '-'))])]), _c('tr', [_c('td', [_vm._v("Pajak")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s("".concat(_vm.akun.pajak, "%")))])]), _c('tr', [_c('td', [_vm._v("Akun Dimasukan Dalam Hutang")]), _c('td', [_vm._v(":")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.akun.hutang == 1 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(" " + _vm._s(_vm.akun.hutang == 1 ? 'Ya' : 'Tidak') + " ")])], 1)]), _c('tr', [_c('td', [_vm._v("Akun Ditampilkan di Laba Rugi")]), _c('td', [_vm._v(":")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.akun.laba_rugi == 1 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(" " + _vm._s(_vm.akun.laba_rugi == 1 ? 'Ya' : 'Tidak') + " ")])], 1)]), _c('tr', [_c('td', [_vm._v("Akun Ditampilkan di Neraca")]), _c('td', [_vm._v(":")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": _vm.akun.neraca == 1 ? 'light-success' : 'light-danger'
    }
  }, [_vm._v(" " + _vm._s(_vm.akun.neraca == 1 ? 'Ya' : 'Tidak') + " ")])], 1)])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }